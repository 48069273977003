<template>
  <el-dialog
    data-testid="toggle-period-modal"
    :title="title"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" :show-message="false">
      <section class="flex flex-col">
        <BaseElFormItem prop="unit">
          <ServiceUnitSelect
            testName="search_unit"
            class="mb-3"
            :model.sync="formData.unit"
            @change="getPeriods"
          />
        </BaseElFormItem>

        <BaseElFormItem prop="date">
          <el-date-picker
            v-model="formData.date"
            data-testid="el-date-picker-search_date"
            editable
            type="date"
            :placeholder="$t('shiftCalendar.dialog.date.placeholder')"
            @change="getPeriods"
          />
        </BaseElFormItem>
      </section>
    </BaseElForm>

    <div v-if="periods.length" class="period-list" style="gap: 10px;">
      <div
        v-for="(p, index) in periods"
        :key="p.start"
        :data-testid="`period-${timeFormat(p.start)}`"
        class="period"
        :class="{
          'status-available': p.status === 'available',
          'status-booking': p.status === 'booking',
          'status-ban': p.status === 'ban',
        }"
        type="info"
        @click="periodClick(p, index)"
      >
        <p>{{ timeFormat(p.start) }}</p>
        <p>{{ statusFormat(p.status) }}</p>
      </div>
    </div>
    <div v-if="formData.date && formData.unit && !periods.length">
      {{ $t('shiftCalendar.dialog.empty.text') }}
    </div>

    <span slot="footer" class="dialog-footer">
      <div>
        <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text') }}</BaseElButton>
        <BaseElButton type="primary" :loading="loading" @click="updatePeriod">{{ $t('common.button.save.text') }}</BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import { GetPeriods, UpdatePeriod } from '@/api/togglePeriod'
import { timesConvert } from '@/utils/helper'
import dayjs from '@/lib/dayjs'
import { i18n } from '@/plugins/i18n/i18n'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'TogglePeriodDialog',
  components: { ServiceUnitSelect },
  props: {
    title: String,
    selectDate: [String, Date],
    selectUnit: [String, Object],
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    const config = ref(null)
    const available = ref([])
    const periods = ref([])
    const interval = ref(0)
    const { shopId } = useShop()

    initFormData({
      date: '',
      unit: '',
    })

    const formRules = computed(() => {
      const rules = {
        date: [noEmptyRules()],
        unit: [noEmptyRules()],
      }
      return rules
    })

    const timeFormat = (dateTime) => {
      return dayjs(dateTime).format('HH:mm')
    }

    const statusFormat = (status) => {
      const statusDict = {
        available: i18n.t('shiftCalendar.status.available.text'),
        booking: i18n.t('shiftCalendar.status.booking.text'),
        ban: i18n.t('shiftCalendar.status.ban.text'),
      }
      return statusDict[status]
    }

    const periodClick = (period, index) => {
      const status = period.status
      if (status === 'available') {
        periods.value[index].status = 'ban'
      }
      if (status === 'ban') {
        periods.value[index].status = 'available'
      }
    }

    const generatePeriod = () => {
      const intervalValue = config.value.timeUnit
      const offset = config.value.timeUnitOffset

      let count = 1
      while (intervalValue * count + offset < 1440) {
        const period = timesConvert(intervalValue * count + offset)
        count += 1
        periods.value.push(period)
      }
    }

    const getPeriods = async () => {
      if (!formData.unit.id) return
      if (formData.date === '') return
      const res = await GetPeriods({
        shopId: shopId.value,
        appointmentUnitId: formData.unit.id,
        start: new Date(`${dayjs(formData.date).format('YYYY/MM/DD')} 00:00`),
        end: new Date(
          `${dayjs(formData.date)
            .add(1, 'd')
            .format('YYYY/MM/DD')} 00:00`,
        ),
      })

      periods.value = res.times
      interval.value = res.timeUnit
    }

    const updatePeriod = async () => {
      if (loading.value) return
      loading.value = true
      const formValid = await checkForm(formRef.value)
      if (!formValid) {
        loading.value = false
        return
      }
      try {
        const tempTimes = periods.value.map(item => ({ ...item, availableVolume: undefined }))
        await UpdatePeriod({
          shopId: shopId.value,
          appointmentUnitId: formData.unit.id,
          times: tempTimes,
        })
        loading.value = false
        window.$message.success(notifyMessage.updateSuccess)
        emit('updated')
        // emit('close')
      } catch (error) {
        loading.value = false
        console.error(error)
      }
    }

    onMounted(async () => {
      if (props.selectDate) formData.date = new Date(props.selectDate)
      if (props.selectUnit) {
        formData.unit = { id: props.selectUnit }
        await getPeriods()
      }
      // generatePeriod()
    })

    return {
      loading,
      formRef,
      formData,
      formRules,
      config,
      available,
      periods,
      interval,
      timeFormat,
      statusFormat,
      periodClick,
      generatePeriod,
      getPeriods,
      updatePeriod,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-form-item {
  @apply mb-0;
}
::v-deep .el-date-editor{
  @apply w-full;
}
.period {
  border: solid gray 1px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.period-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.status-available {
  background: #31d58c;
}

.status-ban {
  background: #a1a1a1;
}

.status-booking {
  color: rgb(238, 238, 238);
  background: #f76b6e;
}
</style>
